import { LocationDeviceClient, PagedListOfLocationDeviceDTO, LocationDeviceFilterFields, } from "@/api/nswag.generated";
export default class LocationDeviceService {
    locationDeviceClient = new LocationDeviceClient();
    async listLocationDevicesAsync(pageSize, pageNumber, orderBy, descending, searchQuery, organizationId, filter) {
        if (filter == null) {
            filter = new LocationDeviceFilterFields();
        }
        const deviceDep = await this.locationDeviceClient.listLocationDevices(filter, pageSize, pageNumber, orderBy, descending, searchQuery, null, organizationId);
        if (deviceDep && deviceDep.locationDevices) {
            return deviceDep.locationDevices;
        }
        return new PagedListOfLocationDeviceDTO();
    }
    async allFromLocationAsync(locationId, teamId = null) {
        return await this.locationDeviceClient.getAllFromLocation(locationId, teamId);
    }
    async getById(id) {
        return await this.locationDeviceClient.getById(id);
    }
    async createAsync(command) {
        return await this.locationDeviceClient.create(command);
    }
    async updateAsync(command) {
        return await this.locationDeviceClient.put(command.id, command);
    }
}
