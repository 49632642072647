import { TeamClient, TeamFilterFields, PagedListOfTeamDTO, } from "@/api/nswag.generated";
export default class TeamService {
    teamsClient = new TeamClient();
    async getTeamsAsync(pageSize, pageNumber, orderBy, descending, searchQuery, filter) {
        if (filter == null) {
            filter = new TeamFilterFields();
        }
        const teams = await this.teamsClient.list(filter, pageSize, pageNumber, orderBy, descending, searchQuery, null);
        if (teams && teams.teams) {
            return teams.teams;
        }
        return new PagedListOfTeamDTO();
    }
    async getOrganizationTeamsAsync(pageSize, pageNumber, orderBy, descending, searchQuery, organizationId, filter) {
        if (filter == null) {
            filter = new TeamFilterFields();
        }
        const teams = await this.teamsClient.listOrganizationTeams(filter, pageSize, pageNumber, orderBy, descending, searchQuery, null, organizationId);
        if (teams && teams.teams) {
            return teams.teams;
        }
        return new PagedListOfTeamDTO();
    }
    async getLocationTeams(locationId) {
        return await this.teamsClient.listLocationTeams(locationId);
    }
    async createAsync(command) {
        return await this.teamsClient.create(command);
    }
    async getById(id) {
        return await this.teamsClient.getById(id);
    }
    async update(command) {
        return await this.teamsClient.put(command.id, command);
    }
}
