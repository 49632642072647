import script from "./OverloadCalendar.vue?vue&type=script&setup=true&lang=ts"
export * from "./OverloadCalendar.vue?vue&type=script&setup=true&lang=ts"

import "./OverloadCalendar.vue?vue&type=style&index=0&id=22315400&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QMenu,QTable,QTr,QTh,QTd});
