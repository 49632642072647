import script from "./ConnectedLifts.vue?vue&type=script&setup=true&lang=ts"
export * from "./ConnectedLifts.vue?vue&type=script&setup=true&lang=ts"

import "./ConnectedLifts.vue?vue&type=style&index=0&id=dc538cde&lang=scss"

const __exports__ = script;

export default __exports__
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QAvatar,QImg,QIcon,QSeparator,QTable,QTr,QTh,QTd,QBtn});
