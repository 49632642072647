import script from "./ContactInfo.vue?vue&type=script&setup=true&lang=ts"
export * from "./ContactInfo.vue?vue&type=script&setup=true&lang=ts"

import "./ContactInfo.vue?vue&type=style&index=0&id=847019e2&lang=scss"

const __exports__ = script;

export default __exports__
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QAvatar,QIcon});
