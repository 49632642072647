import { ref } from "vue";
import { i18n } from "@/main";
const t = i18n.global.t;
const iconsComp = ref([
    {
        icon: "/img/icons/smilies/very_sad.svg",
        class: "icon-1",
        variable: ref(false),
        labelText: "< 80%",
        min: 0.00000000000000001,
        max: 80,
        text: t("healthyTransfer.notOk"),
    },
    {
        icon: "/img/icons/smilies/sad.svg",
        class: "icon-2",
        variable: ref(false),
        labelText: "80-85%",
        min: 80,
        max: 85,
        text: t("healthyTransfer.almostOk"),
    },
    {
        icon: "/img/icons/smilies/neutral.svg",
        class: "icon-3",
        variable: ref(false),
        labelText: "85-90%",
        min: 85,
        max: 90,
        text: t("healthyTransfer.justOk"),
    },
    {
        icon: "/img/icons/smilies/happy.svg",
        class: "icon-4",
        variable: ref(false),
        labelText: "90-95%",
        min: 90,
        max: 95,
        text: t("healthyTransfer.keepUp"),
    },
    {
        icon: "/img/icons/smilies/very_happy.svg",
        class: "icon-5",
        variable: ref(false),
        labelText: "95-100%",
        min: 95,
        max: 100.1,
        text: t("healthyTransfer.excellent"),
    },
]);
export { iconsComp };
